<template>

  <div class="service_wrap d-none" style="background:transparent;" data-aos="fade-right">
    <div class="container">
      <div class="row">
        <div class="title">
          <span>LATEST WORK</span>
          <h2>Recent Favourite Projects</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="proj_loop">
            <img src="/assets/media/images/mockup.png">
            <p>As a property management software, TenantTech lets you track prospects, order state specific contracts,
              gather applications, create move-in paperwork, send notices and much more.</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="proj_loop">
            <img src="/assets/media/images/mockup.png">
            <p>As a property management software, TenantTech lets you track prospects, order state specific contracts,
              gather applications, create move-in paperwork, send notices and much more.</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="proj_loop">
            <img src="/assets/media/images/mockup.png">
            <p>As a property management software, TenantTech lets you track prospects, order state specific contracts,
              gather applications, create move-in paperwork, send notices and much more.</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="proj_loop">
            <img src="/assets/media/images/mockup.png">
            <p>As a property management software, TenantTech lets you track prospects, order state specific contracts,
              gather applications, create move-in paperwork, send notices and much more.</p>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
export default {
  name: "ProjectsComponent"
}
</script>

<style scoped>

</style>