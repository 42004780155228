<template>

  <div class="service_wrap faq_wrap" style="background:transparent;" data-aos="fade-right">
    <div class="container">
      <div class="row">
        <div class="title">
          <span>KNOW MORE</span>
          <h2>Frequently Asked Questions</h2>
        </div>
      </div>

      <div class="row">
        <div class="accordion" id="accordionFAQs">
          <div v-for="(item,index) in items" v-bind:key="item.id" class="accordion-item">
            <h2 class="accordion-header" :id="'heading-'+index">
              <button class="accordion-button" :class="index == 0 ? '':'collapsed'" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+index"
                      :aria-expanded="index == 0 ? 'true':'false'" aria-controls="collapseOne">
                {{ item.question }}
              </button>
            </h2>
            <div :id="'collapse-'+index" class="accordion-collapse collapse" :class="index == 0 ? 'show':''" aria-labelledby="headingOne"
                 data-bs-parent="#accordionFAQs">
              <div class="accordion-body">
                <p v-html="item.answer">  </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>


<script>
export default {
  name: "FaqsComponent",
  props: ['items'],
  components: {},
}
</script>

<style scoped>

</style>