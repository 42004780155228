<template>
    <div class="service_wrap box_area" style="background:transparent;" data-aos="fade-right">
        <div class="container">
            <div class="row">
                <div class="title motionless__banner">
                    <span>TEAM</span>
                    <h2>Our Management</h2>
                </div>
            </div>

            <div class="row">
                <div v-for="item in limitedItems" v-bind:key="item.id" class="col-md-3">
                    <div class="team_loop">
                        <img :src="item.image_path" :alt="item.image_alt">
                        <div class="cont">
                            <h2>{{ item.title }}</h2>
                            <p>{{ item.short_desc }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "ManagementComponent",
    props: ['items'],
    computed: {
        limitedItems() {
            const maxCharacters = 50; // Specify the maximum number of characters for short_desc

            return this.items.map(item => {
                if (item.short_desc.length > maxCharacters) {
                    return {
                        ...item,
                        short_desc: item.short_desc.slice(0, maxCharacters) + '...'
                    };
                }
                return item;
            });
        }
    },
};
</script>
    
<style scoped></style>
