<template>
  <section class="banner">
    <div class="container">
      <div v-if="item != null" class="row">
        <div class="innerbanner">
          <div class="banner_loop d-flex align-items-center">
            <div class="col-md-6">
              <div class="left_content ">
                <h2>{{ item.short_desc }}</h2>
                <p v-html="item.description"></p>
                <router-link v-show="hrefURL != ''" :to="hrefURL" class="btn btn_red hvr-sweep-to-right gray" v-on:click="clearFrom()">{{ bannerTitle != '' ? bannerTitle : "Contact Lucerna Tech"}}</router-link>
                <a v-show="hrefURL == ''" :href="hrefPath" class="btn btn_red hvr-sweep-to-right gray" v-on:click="clearFrom()">{{ bannerTitle != '' ? bannerTitle : "Contact Lucerna Tech"}}</a>
              </div>
            </div>
            <div class="col-md-6">
              <div class="right_img">
                <img :src="item.image_path" :alt="item.image_alt"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row">
        <div class="innerbanner">
          <div class="banner_loop d-flex align-items-center">
            <div class="col-md-6">
              <div class="left_content ">
                <h2>Guiding the Path to Success with Vision and Expertise</h2>
                <p>Our dynamic management team brings together the perfect blend of visionary thinking and exceptional expertise, fueling our organization's journey towards greatness. Together, we shap e the future, guided by their unwavering dedication to success.</p>
                  <router-link v-show="hrefURL != null" :to="hrefURL" class="btn btn_red hvr-sweep-to-right gray" v-on:click="clearFrom()">Contact Lucerna Tech</router-link>
                  <a v-show="hrefURL == null" :href="hrefPath" class="btn btn_red hvr-sweep-to-right gray" v-on:click="clearFrom()">Contact Lucerna Tech</a>
              </div>
            </div>
            <div class="col-md-6">
              <div class="right_img">
                <img src="/images/banner2.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "BannersComponent",
  props: ['item','hrefURL','hrefPath','bannerTitle'],
  components: {},
  methods: {
		clearFrom(){
			document.getElementById("dev_hire").innerHTML= '';
		}
	},
	beforeCreate() {
	},
	beforeMount() {
	},
	mounted() {
	}
}
</script>

<style scoped>
.innerbanner .right_img img {
  width: 450px;
}
</style>