<template>
  <BannersComponent :item="section.data.banner"  :hrefURL="''"  :hrefPath="'#contact-us'" :bannerTitle="''"></BannersComponent>
  <PortfolioComponent :toShow="1" :slug="slug"></PortfolioComponent>
  <ContactUsHome></ContactUsHome>
</template>

<script>

import BannersComponent from "@/components/BannersComponent";
import ContactUsHome from "@/components/ContactUsHome";

import { useRoute } from 'vue-router';
import PortfolioComponent from "@/components/PortfolioComponent";

export default {

  name: "PortfolioPage",
  components: {
    PortfolioComponent,
    BannersComponent,
    ContactUsHome
  },
  watch: {
    '$route.params.slug'(newSlug, oldSlug) {
      console.dir(newSlug, oldSlug)
      this.getPortfolio(newSlug);
    }
  },
  data() {
    return {
      section: {

      },
      slug:null,
    }
  },
  methods: {
    getPortfolio(slug) {
      this.slug = slug == 'local' ? 1 : 0;
      this.axios.get(this.apiURL + '/portfolio/'+ slug).then((response) => {
        this.section = response.data;
      });

      console.log(this.section);

    }
  },
  beforeCreate() {

  },
  beforeMount() {
    let route = useRoute();
    this.getPortfolio(route.params.slug);
  },
  mounted() {
    console.log("AboutUs")

  }
}
</script>