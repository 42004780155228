<template>
  <BannersComponent :item="banner"  :hrefURL="''"  :hrefPath="'#contact-us'" :bannerTitle="''"></BannersComponent>
<!--  <TeamComponentSolution :item="section.data.solution"></TeamComponentSolution>-->
<!--  <SolutionProcess></SolutionProcess>
	<GetInTouch></GetInTouch>
  <HomeSolutions :setStyle="''" :item="section.data.solutions"></HomeSolutions>-->

<!-- NEW SECTION INIT -->

  <div class="service_wrap pb-0" style="background: transparent">
    <div class="container">
      <div class="row">
        <div class="title">
          <h2>{{ solution.detail_heading }}</h2>
          <p>{{ solution.short_desc }}</p>
        </div>
      </div>
    </div>

  </div>

  <div class="">
    <div class="container">



      <div class="row seerv_new_loop ">
        <div class="col-md-4">
          <div class="img_left">
            <div class="wop_img">
              <img :src="solution.detail_image_path" :alt="solution.image_alt"/>
              <router-link to="/contact-us" class="btn btn_red active hvr-sweep-to-right gray">Get a Quote</router-link>
            </div>
          </div>
        </div>
        <div class="col-md-8 d-flex align-items-center">
          <div class="content_right">
            <h2>{{ solution.title }}</h2>
            <div class="description"  v-html="solution.description"></div>
          </div>
        </div>


      </div>
    </div>
  </div>

<!-- NEW SECTION INIT -->


	<FaqsComponent :items="section.data.faqs"></FaqsComponent>
<!--  <ContactUs></ContactUs>-->
</template>

<script>

// import HomeSolutions from "@/components/HomeSolutions";
import BannersComponent from "@/components/BannersComponent";
// import SolutionProcess from "../components/SolutionProcess";
import FaqsComponent from "../components/FaqsComponent";
// import GetInTouch from "../components/GetInTouch";
// import ContactUs from "../components/ContactUs";
/*import TeamComponentSolution from "@/components/TeamComponentSolution";*/

import { useRoute } from 'vue-router';

export default {

  name: "SolutionPage",
  components: {
    /*TeamComponentSolution,*/
    BannersComponent,
    // HomeSolutions,
    FaqsComponent,
    // SolutionProcess,
    // GetInTouch,
    // ContactUs
  },
  watch: {
    '$route.params.slug'(newSlug, oldSlug) {
      console.dir(newSlug, oldSlug)
      this.getTechnology(newSlug);
    }
  },
  data() {
    return {
      section: {

      },
      solution:{},
      banner:{
        short_desc: "",
        description: "",
        image_path:"",
        image_alt:""
      }
    }
  },
  methods: {
    getTechnology(slug) {
      this.axios.get(this.apiURL + '/solution/'+ slug).then((response) => {
        this.section = response.data;

        let ban = {
          short_desc: this.section.data.solution.banner_short_desc,
          description: this.section.data.solution.banner_description,
          image_path:this.section.data.solution.banner_image_path,
          image_alt:""
        }

        this.banner = ban;

        this.solution = this.section.data.solution;
      });

      console.log(this.section);

    }
  },
  beforeCreate() {

  },
  beforeMount() {
    let route = useRoute();
    this.getTechnology(route.params.slug);
  },
  mounted() {

    console.log("AboutUs")

  }
}
</script>