<template>
  <section class="banner">
    <div class="container">
      <div class="row">
        <div class="main_slider">
          <carousel :items-to-show="1" :breakpoints="breakpoints" :autoplay="5500" :wrapAround="true" :transition="1200" :paginationEnabled="true">
            <slide v-for="(item) in items" v-bind:key="item.id">
              <div class="banner_loop d-flex align-items-center">
                <div class="col-md-6">
                  <div class="left_content ">
                    <h2>{{ item.short_desc }}</h2>
                    <p v-html="item.description"></p>
                    <a href="#contact-us" class="btn btn_red hvr-sweep-to-right gray">Contact Lucerna Tech</a>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="right_img">
                    <img :src="item.image_path" :alt="item.image_alt"/>
                  </div>
                </div>
              </div>
            </slide>
            <template #addons>
              <!-- <navigation/> -->
              <pagination/>
            </template>
          </carousel>
        </div>
      </div>
    </div>
  </section>
  <section>

  </section>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import {Carousel, Slide, Pagination} from 'vue3-carousel';

export default {
  name: "HomeBanner",
  props: ['items'],
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      banners: this.items,
      companies: [],
      breakpoints: {
        // 700px and up
        100: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        768: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 1,
          snapAlign: 'start',
        },
      },
    }
  },

}
</script>

<style scoped>
ol.carousel__pagination {
  position: relative;
  z-index: 11;
}

.carousel__pagination li {
  cursor: pointer;
}
</style>