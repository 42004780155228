<template>
  <BannersComponent :item="banner"  :hrefURL="''"  :hrefPath="'#contact-us'" :bannerTitle="''"></BannersComponent>
<!--  <TeamComponent></TeamComponent>-->
<!--	<TechnologyProcess></TechnologyProcess>
	<GetInTouch></GetInTouch>
  <TechnologyComponent :item="section.data.technologies" :section="{title:'TECHNOLOGY STACK',short_desc:'Explore Boundless Potential - Our Tech Stack Awaits!'}" :isHome="false"></TechnologyComponent>-->

  <div class="service_wrap pb-0" style="background: transparent">
    <div class="container">
      <div class="row">
        <div class="title">
          <h2>{{ technology.detail_heading }}</h2>
          <p>{{ technology.short_desc }}</p>
        </div>
      </div>
    </div>

  </div>

  <div class="">
    <div class="container">



      <div class="row seerv_new_loop ">
        <div class="col-md-4">
          <div class="img_left">
            <div class="wop_img">
              <img :src="technology.detail_image_path" :alt="technology.image_alt"/>
              <router-link to="/contact-us" class="btn btn_red active hvr-sweep-to-right gray">Get a Quote</router-link>
            </div>
          </div>
        </div>
        <div class="col-md-8 d-flex align-items-center">
          <div class="content_right">
            <h2>{{ technology.title }}</h2>
            <div class="description"  v-html="technology.description"></div>
          </div>
        </div>


      </div>
    </div>
  </div>

  <FaqsComponent :items="section.data.faqs"></FaqsComponent>
<!--  <ContactUs></ContactUs>-->
</template>

<script>

import BannersComponent from "@/components/BannersComponent";
// import TeamComponent from "../components/TeamComponent";
// import TechnologyProcess from "../components/TechnologyProcess";
// import TechnologyComponent from "../components/TechnologyComponent";
import FaqsComponent from "../components/FaqsComponent";
// import GetInTouch from "../components/GetInTouch";
// import ContactUs from "../components/ContactUs";
/*
import Banners from "../components/BannersComponent";
import Technology from "../components/Technology";

import SolutionsComponent from "@/components/SolutionsComponent";
import ProcessComponent from "@/components/ProcessComponent";
import ExperienceComponent from "@/components/ExperienceComponent";
import TechnologyComponent from "@/components/TechnologyComponent";
import EngagementComponent from "@/components/EngagementComponent";
import StatsComponent from "@/components/StatsComponent";
import ClientsComponent from "@/components/ClientsComponent";
import ProjectsComponent from "@/components/ProjectsComponent";*/
import { useRoute } from 'vue-router';

export default {

  name: "TechnologyPage",
  components: {
    BannersComponent,
    // TeamComponent,
    // TechnologyComponent,
    FaqsComponent,
    // TechnologyProcess,
    // GetInTouch,
    // ContactUs
  },
  watch: {
    '$route.params.slug'(newSlug, oldSlug) {
      console.dir(newSlug, oldSlug)
      this.getTechnology(newSlug);
    }
  },
  data() {
    return {
      section: {

      },
      banner:{
        short_desc: "",
        description: "",
        image_path:"",
        image_alt:""
      },
      technology:{}
    }
  },
  methods: {
    getTechnology(slug) {
      this.axios.get(this.apiURL + '/technology/'+ slug).then((response) => {
        this.section = response.data;

        let ban = {
          short_desc: this.section.data.technology.banner_short_desc,
          description: this.section.data.technology.banner_description,
          image_path:this.section.data.technology.banner_image_path,
          image_alt:""
        }

        this.banner = ban;

        this.technology = this.section.data.technology;
      });

      console.log(this.section);

    }
  },
  beforeCreate() {

  },
  beforeMount() {
    let route = useRoute();
    this.getTechnology(route.params.slug);
  },
  mounted() {
    console.log("AboutUs")

  }
}
</script>