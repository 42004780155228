<template>
  <div class="eng-modal service_wrap plan" style="background:transparent;" data-aos="fade-right">
    <div class="container">
      <div class="row">
        <div class="title">
          <span>OUR ENGAGEMENT MODEL</span>
          <h2>Experience Impactful Collaboration - Let's Connect!</h2>
          <p>With Lucerna Tech, you can leverage our expertise, optimize your team composition, and achieve your project<br> goals efficiently and effectively</p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="img_left">
            <img src="/assets/media/images/img6.gif" alt="" />
          </div>
        </div>

        <div class="col-md-6">
          <div class="right_side">
            <div class="loop_list d-flex align-items-center">
              <div class="img">
                <img src="/assets/media/images/icone1.svg" alt="" />
              </div>
              <div  class="content">
                <h2 style="color:#34B5BA;">Staff Augmentation</h2>
                <p>Bridge any resource or skill gaps within your in-house IT team with our experts managed directly by you.</p>
              </div>
            </div>
            <div class="loop_list d-flex align-items-center">
              <div class="img">
                <img src="/assets/media/images/icone2.svg" alt="" />
              </div>
              <div  class="content">
                <h2 style="color:#BC386B;">Self-Managed Team</h2>
                <p>Enlist a self-managed team under the guidance of Lucerna’s project manager or team lead to drive your IT initiative forward.</p>
              </div>
            </div>
            <div class="loop_list d-flex align-items-center">
              <div class="img">
                <img src="/assets/media/images/icone3.svg" alt="" />
              </div>
              <div  class="content">
                <h2 style="color:#4262AB;">Full Outsourcing</h2>
                <p>We take full responsibility for the quality and associated risks of your specific IT functions ensuring they are effectively managed and taken care of.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "HomeEngagementModal",
  components: {
  },
}
</script>

<style scoped>
.eng-modal .img_left img {
  height: 500px;
}
</style>