<template>
<!--  <BannersComponent :item="section.data.banner"  :hrefURL="''"  :hrefPath="'#contact-us'" :bannerTitle="''"></BannersComponent>
	<AmbGroup></AmbGroup>
	<ContactUsHome></ContactUsHome>-->
  <BannersComponent :item="section.data.banner" :hrefURL="'/contact-us'"  :hrefPath="'#contact-us'" :bannerTitle="''"></BannersComponent>



  <div class="service_wrap box_area" style="background:transparent;" data-aos="fade-right">
    <div class="container">
      <div class="row">
        <div class="title motionless__banner m-0">
          <span>WHAT WE DO</span>
          <h2>{{ about_use_sec_1.short_desc }}</h2>
          <p class="m-0" v-html="about_use_sec_1.description"></p>
        </div>
      </div>
    </div>
  </div>





  <div class="service_wrap pd inner" data-aos="fade-left">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <div class="loop_cat">
            <router-link to="/group-companies?to=abm">
              <div class="img">
                <img src="/images/ambgroup/ABM Info Tech.svg" alt="">
              </div>
              <div class="content">
                <h2>ABM Info Tech</h2>
                <p>IT Business and Solution Provider</p>
              </div>
            </router-link>
          </div>
        </div>
        <div class="col-md-3">
          <div class="loop_cat">
            <router-link to="/group-companies?to=lucerna">
              <div class="img">
                <img src="/images/ambgroup/Lucerna Trading and Tech.svg" alt="">
              </div>
              <div class="content">
                <h2>Lucerna Tech</h2>
                <p>IT Outsourcing and Staff Augmentation</p>
              </div>
            </router-link>
          </div>
        </div>

      <!--
        <div class="col-md-3">
          <div class="loop_cat">
            <router-link to="/group-companies?to=salateen">
              <div class="img">
                <img src="/images/ambgroup/SalateenSyndicate.svg" alt="">
              </div>
              <div class="content">
                <h2>Salateen Syndicate</h2>
                <p>Product and Service Intending</p>
              </div>
            </router-link>
          </div>
        </div>

        <div class="col-md-3">
          <div class="loop_cat">
            <router-link to="/group-companies?to=abm-satuma">
              <div class="img">
                <img src="/images/ambgroup/ABM SATUMA.svg" alt="">
              </div>
              <div class="content">
                <h2>ABM SATUMA</h2>
                <p>UAV Technology</p>
              </div>
            </router-link>
          </div>
        </div>  



        <div class="col-md-3">
          <div class="loop_cat">
            <router-link to="/group-companies?to=abm-wwt">
              <div class="img">
                <img src="/images/ambgroup/ABM WWT.svg" alt="">
              </div>
              <div class="content">
                <h2>ABM WWT</h2>
                <p>IT Services Trading</p>
              </div>
            </router-link>
          </div>
        </div> -->
        
        <div class="col-md-3">
          <div class="loop_cat">
            <router-link to="/group-companies?to=socialpie">
              <div class="img">
                <img src="/images/ambgroup/socialpie.svg" alt="">
              </div>
              <div class="content">
                <h2>SocialPie</h2>
                <p>Engineered for Impact</p>
              </div>
            </router-link>
          </div>
        </div>
        
        <div class="col-md-3">
          <div class="loop_cat">
            <router-link to="/group-companies?to=tsl">
              <div class="img">
                <img src="/images/ambgroup/ABM Pamir.png" alt="">
              </div>
              <div class="content">
                <h2>TSL Travelport</h2>
                <p>Travel GDS Services</p>
              </div>
            </router-link>
          </div>
        </div>



<!--
        <div class="col-md-3">
          <div class="loop_cat">
            <router-link to="/group-companies?to=travtech">
              <div class="img">
                <img src="/images/ambgroup/Travtech.svg" alt="">
              </div>
              <div class="content">
                <h2>Travtech</h2>
                <p>Supply and Sourcing of Turkish Products</p>
              </div>
            </router-link>
          </div>
        </div>





        <div class="col-md-3">
          <div class="loop_cat">
            <router-link to="/group-companies?to=abm-pamir">
              <div class="img">
                <img src="/images/ambgroup/ABMPamir1.svg" alt="">
              </div>
              <div class="content">
                <h2>ABM Pamir</h2>
                <p>Mines, Minerals and Investment</p>
              </div>
            </router-link>
          </div>
        </div> -->

      </div>
    </div>
  </div>


  <div class="service_wrap plan core_value" style="background:transparent;" data-aos="fade-right">
    <div class="container">
      <div class="row">
        <div class="title">
          <h2>{{ about_use_sec_2.short_desc }}</h2>
          <p v-html="about_use_sec_2.description"></p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <div class="loop_values text-center">
            <img src="/images/core1.svg" alt="" />
            <h2>Excellence</h2>
            <p>Striving for excellence in everything we do, delivering high-quality solutions and services that exceed expectations.</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="loop_values text-center">
            <img src="/images/core2.svg" alt="" />
            <h2>Collaboration</h2>
            <p>Fostering a collaborative environment where teamwork, communication, and mutual support are valued, enabling us to achieve remarkable results together.</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="loop_values text-center">
            <img src="/images/core3.svg" alt="" />
            <h2>Innovation</h2>
            <p>Embracing innovation and staying at the forefront of technology advancements to provide cutting-edge solutions that drive growth and transform businesses.</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="loop_values text-center">
            <img src="/images/core4.svg" alt="" />
            <h2>Integrity</h2>
            <p>Operating with utmost integrity, honesty, and transparency, building trust with our clients, partners, and employees through ethical practices and fair dealings.</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="loop_values text-center">
            <img src="/images/core5.svg" alt="" />
            <h2>Customer Centricity</h2>
            <p>Putting our clients at the center of everything we do, understanding their unique needs, and delivering tailored solutions that add value and drive their success.</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="loop_values text-center">
            <img src="/images/core6.svg" alt="" />
            <h2>Continuous Learning</h2>
            <p>Promoting a culture of continuous learning and personal growth, empowering our team to expand their knowledge and skills to stay ahead in a rapidly evolving industry.</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script >

import BannersComponent from "../components/BannersComponent";
/*
import AmbGroup from "../components/AmbGroup";
import ContactUsHome from "../components/ContactUsHome";*/

export default {
  name: "AMBGroup",
  components: {
    // AmbGroup,
    // ContactUsHome,
    BannersComponent
  },
  data() {
    return {
      section: [],
      about_use_sec_1: {},
      about_use_sec_2: {},
    }
  },
  methods: {
    getHome() {
      this.axios.get(this.apiURL + '/getAmbGroup').then((response) => {
        this.section = response.data;
      });

      this.axios.get(this.apiURL + '/getCms?slug=amb-group').then((response) => {
        this.about_use_sec_1 = response.data.data.cmsData[0];
        this.about_use_sec_2 = response.data.data.cmsData[1];
      });

      console.log(this.section);

    }
  },
  beforeMount() {
    this.getHome();
  },
  mounted() {

  }
}
</script>