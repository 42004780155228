<template>
    
<div class="body_nn">
	<div class="content_part_wrapper reverse" data-aos="fade-left">
		<div class="container">
			<span v-for="(aboutus, index) in cmsData" :key="index">
				<span v-if="index>0">					
					<div v-if="index % 2==1" class="row align-items-center">
						<div class="col-md-6">
							<div class="content_part">
								<h2>{{aboutus.short_desc}}</h2>
								<p v-html="aboutus.description"></p>
								<span v-if="aboutus.short_desc == 'The IT Imperative'">
									<router-link to="/?to=technologies" class="btn btn_red hvr-sweep-to-right gray">View our Technologies</router-link>
								</span>
								<span v-else>
									<router-link to="/?to=services" class="btn btn_red hvr-sweep-to-right gray">View our Services</router-link>
								</span>
							</div>
						</div>
						<div class="col-md-6">
							<div class="img_part">
								<!-- <img src="/assets/media/images/about1.svg" alt="" /> -->
								<img :src="aboutus.image_path" :alt="aboutus.image_alt"/>
							</div>
						</div>
					</div>
					<div v-else class="row align-items-center">
						<div class="col-md-6">
							<div class="img_part">
								<!-- <img src="/assets/media/images/about2.svg" alt="" /> -->
								<img :src="aboutus.image_path" :alt="aboutus.image_alt"/>
							</div>
						</div>
						<div class="col-md-6" style="padding-bottom: 60px; padding-top: 60px">
							<div class="content_part">
								<h2>{{aboutus.short_desc}}</h2>
								<p v-html="aboutus.description"></p>
								<router-link to="/hire-developer" class="btn btn_red hvr-sweep-to-right gray">Hire a Developer</router-link>
							</div>
						</div>
					</div>
				</span>
			
			</span>
		</div>
	</div>
</div>

</template>

<script>
export default {
  name: "AboutApproach",
  data() {
    return {
      cmsData: {},
    }
  },
  methods: {
    getHome() {
      this.axios.get(this.apiURL + '/getCms?slug=about-us').then((response) => {
        this.cmsData = response.data.data.cmsData;

        console.log(response.data.data);
      });
    }
  },
  beforeMount() {

  },
  mounted() {

    this.getHome();
  }
}
</script>

<style scoped>

</style>