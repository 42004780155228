<template>
<BannersComponent :item="banner"  :hrefURL="''"  :hrefPath="'#contact-us'" :bannerTitle="''"></BannersComponent>
  <SolutionsComponenttwo :items="section.data.sub_services" :serviceD="section.data.serviceData"></SolutionsComponenttwo>
<!--  <ProcessComponent :item="section.data.serviceData"></ProcessComponent>
  <ExperienceComponent v-if="0" :item="section.data.experience"></ExperienceComponent>-->
  <template v-if="section.data.serviceData.type == 'Technology'">
<!--    <TechnologyComponent :item="section.data.technology" :section="{title:'TECHNOLOGY STACK',short_desc:'Explore Boundless Potential - Our Tech Stack Awaits!'}" :isHome="true"></TechnologyComponent>-->
  </template>
   <template v-else>
<!--    <HomeSolutions :setStyle="'background:transparent;display:none'" :item="section.data.solution"></HomeSolutions>-->
  </template>
  <ProjectsComponent :item="section.data.projects"></ProjectsComponent>
<!--  <EngagementComponent :items="section.data.engage" :style="'background:transparent;'"></EngagementComponent>-->
<!--  <StatsComponent :items="section.data.stats" :heading="0"></StatsComponent>-->
<!--  <PortfolioComponent :toShow="1" :slug="1"></PortfolioComponent>
  <PortfolioComponent :toShow="0" :slug="0"></PortfolioComponent>-->
  <ContactUs></ContactUs>

</template>


<script>

import BannersComponent from "@/components/BannersComponent";
import SolutionsComponenttwo from "@/components/SolutionsComponenttwo";
//import ProcessComponent from "@/components/ProcessComponent";
//import ExperienceComponent from "@/components/ExperienceComponent";
//import TechnologyComponent from "@/components/TechnologyComponent";
// import EngagementComponent from "@/components/EngagementComponent";
// import StatsComponent from "@/components/StatsComponent";
//import ProjectsComponent from "@/components/ProjectsComponent";
import { useRoute } from 'vue-router';
// import PortfolioComponent from "@/components/PortfolioComponent";
//import ContactUs from "../components/ContactUs";
// import HomeSolutions from "@/components/HomeSolutions";


export default {

  name: "ServicesDup",
  watch: {
    '$route.params.slug'(newSlug, oldSlug) {
      console.dir(newSlug, oldSlug)
      this.getServicesAPI(newSlug);
    }
  },
  components: {
    // HomeSolutions,
    //PortfolioComponent,
    SolutionsComponenttwo,
    //ProcessComponent,
    //ExperienceComponent,
    //TechnologyComponent,
    //EngagementComponent,
    //StatsComponent,
    //ProjectsComponent,
    BannersComponent,
    //ContactUs

  },
  data() {
    return {
      section: {

      },
      banner:{
        short_desc: "",
        description: "",
        image_path:"",
        image_alt:""
      }
    }
  },
  methods: {
    getServicesAPI(newSlug) {
      console.log(newSlug)

      this.axios.get(this.apiURL + '/service/'+ newSlug).then((response) => {
        this.section = response.data;

        let ban = {
          short_desc: this.section.data.serviceData.banner_short_desc,
          description: this.section.data.serviceData.banner_description,
          image_path:this.section.data.serviceData.banner_image_path,
          image_alt:""
        }

        this.banner = ban
      });

      console.log(this.section);

    }
  },
  beforeCreate() {

  },
  beforeMount() {
    let route = useRoute();
    this.getServicesAPI(route.params.slug);
  },
  mounted() {
    console.log("AboutUs")

  }
}
</script>