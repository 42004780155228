<template>
	<BannersComponent :item="section.data.banner" :hrefURL="'/contact-us'"  :hrefPath="'#contact-us'" :bannerTitle="''"></BannersComponent>
	<ManagementComponent :items="section.data.team"></ManagementComponent>
<!--	<ContactUsHome></ContactUsHome>-->
</template>
<script>


import BannersComponent from "@/components/BannersComponent";
import ManagementComponent from "@/components/ManagementComponent";
// import ContactUsHome from "@/components/ContactUsHome";

export default {
  name: "ManagementPage",
  components: {
    ManagementComponent,
    BannersComponent,
    // ContactUsHome
  },
  data() {
    return {
      section: {

      },
    }
  },
  methods: {
    getManagement() {
      this.axios.get(this.apiURL + '/getTeam').then((response) => {
        this.section = response.data;
      });

      console.log(this.section);

    }
  },
  beforeMount() {
    this.getManagement();
  },
  mounted() {
    console.log("AboutUs")

  }
}
</script>

