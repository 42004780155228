import {createWebHistory, createRouter} from "vue-router";

// import VueAxios from 'vue-axios'

import IndexPage from "@/pages/IndexPage.vue";
import AboutUsComponent from "@/pages/AboutUsPage";
import AMBComponent from "@/pages/amb-group";
import GroupCompanies from "@/pages/group-companies";
import ServicesPage from "@/pages/ServicesPage";
import ServicesDup from "@/pages/ServicesDup";
import TechnologyPage from "@/pages/TechnologyPage";
import SolutionPage from "@/pages/SolutionPage";
import PortfolioPage from "@/pages/PortfolioPage";
import NotFoundComponent from "@/pages/NotFoundComponent";
import ManagementPage from "@/pages/ManagementPage";
import ContactUsPage from "@/pages/ContactUsPage";
import HireDeveloperPage from "@/pages/HireDeveloperPage"
import TestPage from "@/pages/TestPage";


// import axios from 'axios'

const routes = [
    {
        path: "/",
        name: "Home",
        component: IndexPage,
        meta: {
            title: "Lucerna-Tech"
        }
    },
    {
        path: "/aboutus",
        name: "AboutUs",
        component: AboutUsComponent,
        meta: {
            title: "LucernaTech"
        }
    },
    {
        path: "/group-companies",
        name: "GroupCompanies",
        component: GroupCompanies,
        meta: {
            title: "LucernaTech"
        }
    },
    {
        path: "/amb-group",
        name: "AMBComponent",
        component: AMBComponent,
        meta: {
            title: "LucernaTech"
        }
    },
    {
        path: "/services-old/:slug",
        name: "ServicesPage",
        component: ServicesPage,
        meta: {
            title: "LucernaTech"
        }
    },
    {
        path: "/services/:slug",
        name: "ServicesDup",
        component: ServicesDup,
        meta: {
            title: "LucernaTech"
        }
    },
    {
        path: "/technologies/:slug",
        name: "Technologies",
        component: TechnologyPage,
        meta: {
            title: "LucernaTech - Technologies"
        }
    },
    {
        path: "/solutions/:slug",
        name: "Solutions",
        component: SolutionPage,
        meta: {
            title: "LucernaTech - Solutions"
        }
    },
    {
        path: "/portfolio/:slug",
        name: "Portfolio",
        component: PortfolioPage,
        meta: {
            title: "LucernaTech - Portfolio"
        }
    },
    {
        path: "/management",
        name: "ManagementPage",
        component: ManagementPage,
        meta: {
            title: "LucernaTech"
        }
    },
    {
        path: "/contact-us",
        name: "ContactUsPage",
        component: ContactUsPage,
        meta: {
            title: "LucernaTech - ContactUS"
        }
    },
    {
        path: "/hire-developer",
        name: "HireDeveloper",
        component: HireDeveloperPage,
        meta: {
            title: "LucernaTech"
        }
    },
    {
        path: "/test-animation",
        name: "TestPage",
        component: TestPage,
        meta: {
            title: "LucernaTech"
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFoundComponent,
        meta: {
            title: "LucernaTech - Not Found"
        }
    },


];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        // always scroll to top
        return {top: 0}
    },
});

router.beforeEach((to, from, next) => {
    /*if (to != from) {
        document.title = `${to.meta.title}`;

        let routeName = to.name;
        let baseName = null;
        if (routeName == "Home") {
            baseName = "index"
        } else if (routeName == "CompareRate") {
            baseName = "compare-rate"
        } else if (routeName == "UnSubscribe") {
            baseName = null
        } else if (routeName == "YourQuote") {
            baseName = "your-quote"
        } else if (routeName == "ApiBooking") {
            baseName = null
        } else if (routeName == "YourDetail") {
            baseName = "your-detail"
        } else if (routeName == "AboutUs") {
            baseName = "about-us";
        } else if (routeName == "Airport") {
            baseName = null
        } else if (routeName == "ContactUs") {
            baseName = "contactus"
        } else if (routeName == "FAQs") {
            baseName = "faq"
        } else if (routeName == "MyBooking") {
            baseName = "my-bookings"
        } else if (routeName == "MyBookingDetail") {
            baseName = null
        } else if (routeName == "Reviews") {
            baseName = null
        } else if (routeName == "TermsAndCondition") {
            baseName = "terms"
        } else if (routeName == "PrivacyPolicy") {
            baseName = "Privacy Policy"
        } else if (routeName == "Success") {
            baseName = "success"
        } else if (routeName == "Cancel") {
            baseName = null
        } else if (routeName == "CompanyTerms") {
            baseName = null
        } else if (routeName == "NotFound") {
            baseName = null
        } else {
            baseName = null;
        }

        console.log(baseName)

        if (baseName != null) {
            let formData = {'base_name': baseName};
            console.log(formData);
            console.log(to.name)
            axios.post('https://app.compareparkingprices.co.uk/api/index.php/home/getSEOInfo', formData)
                .then(function (response) {
                    console.log(response)
                    if (response.status) {
                        console.log(response.data.data);
                        document.title = response.data.data.seo_title;
                        console.log(document.querySelector('meta[name="title"]'))
                        document.querySelector('meta[name="title"]').content = response.data.data.seo_title
                        document.querySelector('meta[name="description"]').content = response.data.data.seo_description
                        document.querySelector('meta[name="keywords"]').content = response.data.data.seo_keyword

                    }
                })
        }

    }
    next();*/

    next();

    console.dir(to, from, next);
})

export default router;