<template>
  <app-header :menuItem="menuBar"/>
  <router-view />
  <app-footer :menuItem="menuBar"/>

</template>

<script>

import AppHeader from "@/components/HeaderComponent";
import AppFooter from "@/components/FooterComponent";
import AOS from "aos";

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter
  },
  metaInfo: {
    title: 'LUCERNA-TECH',
        meta: [
      { name: 'description', content: 'My page description' },
      { name: 'keywords', content: 'vue, meta, example' }
    ],
        link: [
      { rel: 'icon', type: 'image/png', href: '/favicon.png' }
    ]
  },
  data() {
    return {
      menuBar: [],
    }
  },
  methods: {
    getMenu() {
      this.axios.get(this.apiURL + '/get-menu').then((response) => {
        this.menuBar = response.data;
      });
    }
  },
  beforeMount() {
    this.getMenu();
  },
  mounted() {
    AOS.init();
    
  }

}
</script>

<style>
.admin-loader{
  z-index: 99999;
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.28);
  top: 0;
}



.loader  {
  animation: rotate 3s infinite;
  height: 50px;
  width: 50px;
  position: relative;
  top: 50%;
  left: 50%;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: '';
  display: block;
  height: 20px;
  width: 20px;
}
.loader:before {
  animation: ball1 1s infinite;
  background-color: #cb2025;
  box-shadow: 30px 0 0 #f8b334;
  margin-bottom: 10px;
}
.loader:after {
  animation: ball2 1s infinite;
  background-color: #00a096;
  box-shadow: 30px 0 0 #97bf0d;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }
  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }
  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f8b334;
  }
  50% {
    box-shadow: 0 0 0 #f8b334;
    margin-bottom: 0;
    -webkit-transform: translate(15px,15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #f8b334;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #97bf0d;
  }
  50% {
    box-shadow: 0 0 0 #97bf0d;
    margin-top: -20px;
    -webkit-transform: translate(15px,15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #97bf0d;
    margin-top: 0;
  }
}
</style>
