<template>

  <div class="service_wrap faq_wrap" style="background:transparent;" data-aos="fade-right">
    <div class="container">
      <div class="row">
        <div class="title">
          <span>INDUSTRY EXPERIENCE</span>
          <h2>Our Business Repertoire is Diverse</h2>
        </div>
      </div>


      <div class="row">
        <div class="col">
          <div class="loop_exp">
            <img src="/assets/media/images/img8.svg" alt="">
            <h2>On-Demand Solutions</h2>
          </div>
        </div>
        <div class="col">
          <div class="loop_exp">
            <img src="/assets/media/images/img8.svg" alt="">
            <h2>Education & eLearning</h2>
          </div>
        </div>
        <div class="col">
          <div class="loop_exp">
            <img src="/assets/media/images/img8.svg" alt="">
            <h2>Healthcare & Fitness</h2>
          </div>
        </div>
        <div class="col">
          <div class="loop_exp">
            <img src="/assets/media/images/img8.svg" alt="">
            <h2>Social Networking</h2>
          </div>
        </div>
        <div class="col">
          <div class="loop_exp">
            <img src="/assets/media/images/img8.svg" alt="">
            <h2>Travel & Hospitality</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="loop_exp">
            <img src="/assets/media/images/img8.svg" alt="">
            <h2>On-Demand Solutions</h2>
          </div>
        </div>
        <div class="col">
          <div class="loop_exp">
            <img src="/assets/media/images/img8.svg" alt="">
            <h2>Education & eLearning</h2>
          </div>
        </div>
        <div class="col">
          <div class="loop_exp">
            <img src="/assets/media/images/img8.svg" alt="">
            <h2>Healthcare & Fitness</h2>
          </div>
        </div>
        <div class="col">
          <div class="loop_exp">
            <img src="/assets/media/images/img8.svg" alt="">
            <h2>Social Networking</h2>
          </div>
        </div>
        <div class="col">
          <div class="loop_exp">
            <img src="/assets/media/images/img8.svg" alt="">
            <h2>Travel & Hospitality</h2>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
export default {
  name: "ExperienceComponent"
}
</script>

<style scoped>

</style>