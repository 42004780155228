<template>
  <section class="banner">
    <div class="container">

      <div class="row">
        <div class="innerbanner">
          <div class="banner_loop d-flex align-items-center">
            <div class="col-md-6">
              <div class="left_content ">
                <h2>Guiding the Path to Success with Vision and Expertise</h2>
                <p>Our dynamic management team brings together the perfect blend of visionary thinking and exceptional expertise, fueling our organization's journey towards greatness. Together, we shap e the future, guided by their unwavering dedication to success.</p>
                <a href="#contact-us" class="btn btn_red hvr-sweep-to-right gray" v-on:click="clearFrom()">Contact Lucerna Tech</a>
              </div>
            </div>
            <div class="col-md-6">
              <div class="right_img">
                <lottie-animation
                    :path="'assets/json/animations/web-development.json'"
                    :speed="0.5"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="row">
    <div class="col-3">
      <lottie-animation
          :path="'assets/json/animations/web-development.json'"
          :speed="0.5"
      />
    </div>
    <div class="col-3">
      <lottie-animation
          :path="'assets/json/animations/big-data.json'"
          :speed="0.5"
      />
    </div>
    <div class="col-3">
      <lottie-animation
          :path="'assets/json/animations/business-continuity.json'"
          :speed="0.5"
      />
    </div>
    <div class="col-3">
      <lottie-animation
          :path="'assets/json/animations/cyber-security.json'"
          :speed="0.5"
      />
    </div>
    <div class="col-3">
      <lottie-animation
          :path="'assets/json/animations/data-centre.json'"
          :speed="0.5"
      />
    </div>
    <div class="col-3">
      <lottie-animation
          :path="'assets/json/animations/dev-ops.json'"
          :speed="0.5"
      />
    </div>
    <div class="col-3">
      <lottie-animation
          :path="'assets/json/animations/engagement-model.json'"
          :speed="0.5"
      />
    </div>
    <div class="col-3">
      <lottie-animation
          :path="'assets/json/animations/iot-apps.json'"
          :speed="0.5"
      />
    </div>
    <div class="col-3">
      <lottie-animation
          :path="'assets/json/animations/project-management.json'"
          :speed="0.5"
      />
    </div>
  </div>







</template>

<script>

import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs


export default {
  name: "TestPage",
  components: {
    LottieAnimation
  },

}
</script>

<style scoped>

</style>