<template>
  <footer id="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-5">
          <div class="widget">
            <img src="/assets/media/images/logo.svg" alt="" />
            <p v-html="configData.site_description"></p>
            <div class="social">
              <span>Find Us On</span>
              <ul class="nav">
                <li class="nav-item"><a target="_blank" :href="configData.instagram_url"><i class="fa-brands fa-twitter"></i></a></li>
                <li class="nav-item"><a target="_blank" :href="configData.facebook_url"><i class="fa-brands fa-facebook-f"></i></a></li>
                <li class="nav-item"><a target="_blank" :href="configData.youtube_url"><i class="fa-brands fa-youtube"></i></a></li>
                <li class="nav-item"><a target="_blank" :href="configData.tiktok_url"><i class="fa-brands fa-google-plus-g"></i></a></li>
              </ul>
            </div>

            <div class="newletter">
              <span>Join Our Newsletter</span>
              <form>
                <input type="email" placeholder="Enter your Email" />
                <input type="submit" name="" value="" />
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-7">
          <div class="row">
            <div class="col-md-5">
              <div class="widget links">
                <h2>Our Services</h2>
                <ul>
                  <li v-for="item in menuItem.services" v-bind:key="item.id">
                    <router-link :to="'/services/' + item.slug">
                      {{ item.name }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-4">
              <div class="widget links">
                <h2>Our Solutions</h2>
                <ul>
                  <li v-for="item in menuItem.solutions" v-bind:key="item.id">
                    <router-link :to="'/solutions/' + item.slug">
                      {{ item.title }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-3">
              <div class="widget links">
                <h2>Technology</h2>
                <ul>
                  <li v-for="item in menuItem.technologies" v-bind:key="item.id">
                    <router-link :to="'/technologies/' + item.slug">
                      {{ item.title }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom_footer">
      <div class="container">
        <div class="row ">
          <div class="d-flex justify-content-between">
            <p>Copryright-2023 © Lucerna Tech, All Rights Reserved | Designed & Developed by<a href="https://skynetsolutionz.com/" target="_blank"> Skynet Solutionz</a></p>
            <ul class="nav">
              <li><a href="#">Privacy Policy</a></li>
              <li><a href="#">Terms & Conditions</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
  props: ['menuItem'],
  data(){
    return{
      configData : {
        site_description:"",
        instagram_url:"",
        facebook_url:"",
        youtube_url:"",
        tiktok_url:"",
      }
    }
  },

  methods: {

    stringToSlug(str) {
      str = str.toLowerCase(); // Convert the string to lowercase
      str = str.replace(/[^a-z0-9]/g, '-'); // Replace non-alphanumeric characters with a hyphen
      str = str.replace(/-+/g, '-'); // Replace multiple consecutive hyphens with a single hyphen
      str = str.trim('-'); // Trim any leading or trailing hyphens
      return str;
    },
    getFooter() {
      this.axios.get(this.apiURL + '/getConfigData').then((response) => {
        this.configData = response.data.config;

        console.log(this.configData);
      });
    }
  },
  created(){
    this.getFooter();

    console.log(this.menuItem);
  }
}
</script>