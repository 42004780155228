import { createApp } from 'vue'

import App from './App.vue'
import router from './router'

import axios from 'axios'
import VueAxios from 'vue-axios'

import './assets/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'

import "./assets/css/hover-min.css";
import "./assets/css/style.css";


import Bowser from "bowser";
import { vMaska } from "maska"


import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import moment from 'moment'

import VueMeta from 'vue-meta'


/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
library.add(faUserSecret,faTwitter)

import "./assets/css/responsive.css";

import 'aos/dist/aos.css'

const app = createApp(App);




app.config.globalProperties.apiURL = 'https://api.lucerna-tech.com/api'
// app.config.globalProperties.apiURL = 'http://lucerna-tech.test/api'
app.config.globalProperties.siteURL = "http://localhost:8080"

app.use(router)
    .use(VueMeta)
    .use(VueAxios, axios)
    .use(VueSweetalert2)
    .use(moment)
    .directive("maska", vMaska)
    .use(Bowser)
    .component('Datepicker', Datepicker)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app');
