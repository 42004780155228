<template>
    

    <div class="service_wrap faq_wrap"  data-aos="fade-left">
	<div class="container">
		<div class="row">
			<div class="title">
				<span>OUR PROCESS</span>
				<h2>{{ item.process_heading }}</h2>
			</div>
		</div>

		<div class="row process_wrap">
			<div class="col-md-3">
				<div class="process_loop">
					<div class="content_prg d-flex align-items-end">
						<div style="float: left; width: 100%;">
							<p>{{ item.description_1}}</p>
							<h2>{{  item.heading_1 }}</h2>
						</div>
					</div>
					<div class="number">01</div>
					<div class="img_prg d-flex align-items-center">
						<img :src="item.image_1_path" alt="">
					</div>
				</div>
			</div>
			<div class="col-md-3">
				<div class="process_loop">
					<div class="img_prg d-flex align-items-center">
						<img :src="item.image_2_path" alt="">
					</div>
					<div class="number">02</div>
					<div class="content_prg">
            <h2>{{  item.heading_2 }}</h2>
            <p>{{ item.description_2}}</p>
					</div>
				</div>
			</div>
			<div class="col-md-3">
				<div class="process_loop">
					<div class="content_prg d-flex align-items-end">
						<div style="float: left; width: 100%;">
              <p>{{ item.description_3}}</p>
              <h2>{{  item.heading_3 }}</h2>
						</div>
					</div>
					<div class="number">03</div>
					<div class="img_prg d-flex align-items-center">
						<img :src="item.image_3_path" alt="">
					</div>
				</div>
			</div>
			<div class="col-md-3">
				<div class="process_loop">
					<div class="img_prg d-flex align-items-center">
						<img :src="item.image_4_path" alt="">
					</div>
					<div class="number">04</div>
					<div class="content_prg">
            <h2>{{  item.heading_4 }}</h2>
            <p>{{ item.description_4}}</p>
          </div>
				</div>
			</div>
		</div>
	</div>
</div>


</template>


<script>
export default {
  name: "ProcessComponent",
  props :['item']
}
</script>

<style scoped>

</style>