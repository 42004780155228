<template>
	<BannersComponent :item="response.data.banner" :hrefURL="'/contact-us'"  :hrefPath="'#contact-us'" :bannerTitle="''"></BannersComponent>
	<GroupCompanies></GroupCompanies>

	<!-- {{ response.data }} -->

<!-- {{ response }} -->


</template>

<script >

import BannersComponent from "../components/BannersComponent";
import GroupCompanies from "../components/GroupCompanies";
import {useRoute} from "vue-router";

export default {
  name: "AMBGroup",
  components: {
    BannersComponent,
    GroupCompanies
  },
  data() {
    return {
      response: [],
      routes:null,
    }
  },
  methods: {
    async getHome() {
      await this.axios.get(this.apiURL + '/getAmbGroup').then((response) => {
        this.response = response.data;
      });

      console.log(this.response);

      console.log(this.routes);
      console.log(this.routes.query);
      if(this.routes.query.to != undefined){
        console.log(this.routes.query.to)
        let scro = this.routes.query.to;
        setTimeout(function (){
          document.getElementById(scro).scrollIntoView({ behavior: 'smooth' });
        },1000)
      }

    }
  },
  beforeMount() {
    this.getHome();
  },
  mounted() {
    this.routes = useRoute();
  }
}
</script>