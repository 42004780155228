
<template>
  <HomeBanner :items="homeSection.banners"></HomeBanner>
<template v-for="section in homeSection.home_sections" v-bind:key="section.id">
    <template v-if="section.slug == 'stats'">
      <StatsComponent :items="section.stats" :heading="1"></StatsComponent>
    </template>
       <template v-if="section.slug == 'services'">
         <HomeServices :items="section.services" :section="section"></HomeServices>
       </template>
    <template v-if="section.slug == 'technologies'">
         <TechnologyComponent :item="section.technologies" :section="section" :isHome="true"></TechnologyComponent>
       </template>
       <template v-if="section.slug == 'solutions'">
         <HomeSolutions :setStyle="''" :item="section.solutions"></HomeSolutions>
       </template>
       <template v-if="section.slug == 'engagement'">
         <HomeEngagementModal :items="section.engagement"></HomeEngagementModal>
       </template>
  </template>
  <ContactUsHome :item="contact" :setStyle="'display:none'"></ContactUsHome>

</template>

<script >

import HomeBanner from "../components/HomeBanner";
import StatsComponent from "../components/StatsComponent";
import HomeServices from "../components/HomeServices";
import HomeEngagementModal from "../components/HomeEngagementModal";
import HomeSolutions from "../components/HomeSolutions";
import TechnologyComponent from "../components/TechnologyComponent";
import ContactUsHome from "@/components/ContactUsHome";

import { useRoute } from 'vue-router';


export default {
  name: "IndexPage",
  components: {
    ContactUsHome,
    HomeBanner,
    StatsComponent,
    HomeServices,
    HomeEngagementModal,
    HomeSolutions,
    TechnologyComponent
  },
  data() {
    return {
      homeSection: [],
      route:null,
    }
  },
  methods: {
    async getHome() {
      await this.axios.get(this.apiURL + '/gethomeData').then((response) => {
        this.homeSection = response.data;
      });
      console.log(this.homeSection);

      this.axios.get(this.apiURL + '/getConfigData').then((response) => {
        this.configData = response.data.config;

        console.log(this.configData);
      });

      console.log(this.routes);
      console.log(this.routes.query);
      if(this.routes.query.to != undefined){
        console.log(this.routes.query.to)
          let scro = this.routes.query.to;
          setTimeout(function (){
            document.getElementById(scro+'-section').scrollIntoView({ behavior: 'smooth' });
          },1000)
      }

    }
  },
  beforeMount() {

  },
  mounted() {
    this.routes = useRoute();
    this.getHome();


  }
}
</script>

