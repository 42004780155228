<template>
    
    <div class="service_wrap contat_wrapper pb-0" style="background:transparent;" data-aos="fade-right">
	<div class="container">
		<div class="row">
			<div class="title apm">
				<span>WHAT WE DO</span>
				<h2>ABM Group</h2>
				<p>ABM Group is a multifaceted organization that specializes in a wide array of fields, demonstrating proficiency across diverse industries and sectors, including Information Technology (covering Information Security, Cyber Security, Software, and Data Centers), Aviation, Surveillance, and Defense. With a steadfast commitment to principles of dedication, attentiveness, and foresight, the group has nurtured eight prominent companies that have consistently thrived.</p>
			</div>
		</div>
	</div>
</div>



  <div class="amp_wrapper" data-aos="fade-left">
    <div class="container">
      <div id="abm" class="row abm_loop">
        <div class="col-md-5">
          <div class="img_left">
            <img src="/images/ambgroup/ABM Info Tech.svg" alt="">
          </div>
        </div>
        <div class="col-md-7 d-flex align-items-center">
          <div class="content_right">
            <div  class="tit">
              <h2>ABM Info Tech</h2><span>( IT Business and Solution Provider )</span>
            </div>
            <p>Established in 1983, emerged as a leading provider of IT consulting, solutions, and support services. With a focus on delivering comprehensive technology solutions, they played a crucial role in assisting businesses with their IT needs, offering expertise in consulting, implementation, and ongoing support to optimize operational efficiency and drive innovation.</p>
          </div>
        </div>
      </div>
      <div id="lucerna" class="row abm_loop">
        <div class="col-md-5">
          <div class="img_left">
            <img src="/images/ambgroup/Lucerna Trading and Tech.svg" alt="">
          </div>
        </div>
        <div class="col-md-7 d-flex align-items-center">
          <div class="content_right">
            <div  class="tit">
              <h2>Lucerna Tech</h2><span>( IT Outsourcing and Staff Augmentation )</span>
            </div>
            <p>Lucerna Tech, established in 2010, collaborated with ABMIT to provide comprehensive business facilitation services for groups, along with IT and other technology support services worldwide to meet the evolving technological needs of businesses on a global scale.</p>
          </div>
        </div>
      </div>
      <div id="tsl" class="row abm_loop">
        <div class="col-md-5">
          <div class="img_left">
            <img src="/images/ambgroup/socialpie.svg" alt="" style="width:66%">
          </div>
        </div>
        <div class="col-md-7 d-flex align-items-center">
          <div class="content_right">
            <div  class="tit">
              <h2>SocialPie</h2><span>( Engineered for Impact )</span>
            </div>
            <p>SocialPie Technologies is a dynamic and forward-thinking entity that combines the expertise and legacy of the ABM Group with a commitment to driving progress in the technology landscape. We aim to tackle the transformative power of technology to drive growth and create meaningful change.</p>
          </div>
        </div>
      </div>
      <!--
      <div id="salateen" class="row abm_loop">
        <div class="col-md-5">
          <div class="img_left">
            <img src="/images/ambgroup/SalateenSyndicate.svg" alt="">
          </div>
        </div>
        <div class="col-md-7 d-flex align-items-center">
          <div class="content_right ">
            <div  class="tit">
              <h2>Salateen Syndicate</h2><span>( Product and Service Intending )</span>
            </div>
            <p>Salateen Syndicate emerged as a leading company specializing in products and services indenting in 1960. They offered effective procurement solutions for businesses seeking to import goods from international suppliers, facilitating the seamless acquisition of a wide range of products to meet their specific needs.</p>
          </div>
        </div>
      </div>
      <div id="abm-satuma" class="row abm_loop">
        <div class="col-md-5">
          <div class="img_left">
            <img src="/images/ambgroup/ABM SATUMA.svg" alt="">
          </div>
        </div>
        <div class="col-md-7 d-flex align-items-center">
          <div class="content_right">
            <div  class="tit">
              <h2>ABM SATUMA</h2><span>( UAV Technology )</span>
            </div>
            <p>ABM SATUMA, established in 1996 and later rebranded in 2016, specialized in the design, manufacture, supply, and consulting services for Unmanned Aerial Vehicles (UAVs). With their expertise in UAV technology, ABM SATUMA provided comprehensive solutions for various industries and applications   </p>
          </div>
        </div>
      </div>
      <div id="abm-wwt" class="row abm_loop">
        <div class="col-md-5">
          <div class="img_left">
            <img src="/images/ambgroup/ABM WWT.svg" alt="">
          </div>
        </div>
        <div class="col-md-7 d-flex align-items-center">
          <div class="content_right">
            <div  class="tit">
              <h2>ABM WWT</h2><span>( IT Services Trading )</span>
            </div>
            <p>ABM WWT, founded in 1995, established itself as a reputable company specializing in general trading along with IT services and support. With a wide range of products and solutions, they catered to diverse business needs, offering comprehensive IT services</p>
          </div>
        </div>
      </div>
      -->
      <div id="tsl" class="row abm_loop">
        <div class="col-md-5">
          <div class="img_left">
            <img src="/images/ambgroup/ABM Pamir.png" alt="">
          </div>
        </div>
        <div class="col-md-7 d-flex align-items-center">
          <div class="content_right">
            <div  class="tit">
              <h2>TSL Travelport</h2><span>( Travel GDS Services )</span>
            </div>
            <p>TSL Travelport, established in 1997, emerged as a prominent provider of Travel Global Distribution System (GDS) services in Pakistan, Turkey, and the Central Asian States. TSL Travelport played a crucial role in connecting travel agents, airlines, hotels, and other travel service providers, enabling seamless travel arrangements and enhancing the travel industry in the region.`</p>
          </div>
        </div>
      </div>
      <!--
      <div id="travtech" class="row abm_loop">
        <div class="col-md-5">
          <div class="img_left">
            <img src="/images/ambgroup/Travtech.svg" alt="">
          </div>
        </div>
        <div class="col-md-7 d-flex align-items-center">
          <div class="content_right">
            <div  class="tit">
              <h2>Travtech</h2><span>( Supply and Sourcing of Turkish Products )</span>
            </div>
            <p>Travtech, established in 2014, specialized in partnership, sourcing, supply, and facilitation services for customers interested in Turkish products. With a strong network and expertise in the Turkish market, Travtech served as a reliable intermediary, connecting international buyers with Turkish suppliers.</p>
          </div>
        </div>
      </div>
      <div id="abm-pamir" class="row abm_loop">
        <div class="col-md-5">
          <div class="img_left">
            <img src="/images/ambgroup/ABMPamir1.svg" alt="">
          </div>
        </div>
        <div class="col-md-7 d-flex align-items-center">
          <div class="content_right">
            <div  class="tit">
              <h2>ABM Pamir</h2><span>( Mines, Minerals and Investment )</span>
            </div>
            <p>ABM Pamir, established in 2019, focused on the development of the travel and tourism sector, as well as mines, minerals, and investment. They played a pivotal role in promoting and enhancing the travel and tourism industry by offering services such as tour packages, travel planning, hospitality management, and destination marketing.</p>
          </div>
        </div>
      </div> -->
    </div>
  </div>

</template>

<script>
export default {
  name: "GroupCompanies"
}
</script>

<style scoped>

</style>