<template>
    
<div class="service_wrap box_area" style="background:transparent;" data-aos="fade-right">
	<div class="container">
		<div class="row">
			<div class="title motionless__banner">
				
				<span>ABOUT US</span>
				<h2>{{ about_use_sec_1.short_desc }}</h2>
				<!-- <h2>{{cmsData}}</h2> -->
				<p v-html="about_use_sec_1.description"></p>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="loop_patch d-flex align-items-center">
					<div class="content_left">
						<h2>Part of ABM Info Tech</h2>
						<p>Pakistan's leading provider of technology services and solutions since 1983</p>
					</div>
					<div class="rigt_img">
						<img src="/assets/media/images/img1.svg" alt="" />
					</div>
				</div>
				<div class="loop_patch d-flex align-items-center" style="background: #A8E8F3;">
					<div class="content_left">
						<h2>ABM Group</h2>
						<p>Pakistan's oldest business house with 8 flagship companies with core expertise in IT, Aviation, Defense & Indenting, UAVs, General Trading, Travel & Tourism</p>
					</div>
					<div class="rigt_img">
						<img src="/assets/media/images/img2.svg" alt="" />
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="loop_patch d-flex align-items-center half" style="background:#FAE6DF;">
					<div class="content_left">
						<h2>50+</h2>
						<p>Highly qualified professionals and accredited resources</p>
					</div>
					<div class="rigt_img">
						<img src="/assets/media/images/img3.svg" alt="" />
					</div>
				</div>
				<div class="loop_patch d-flex align-items-center half" style="background:#D4F0FD;">
					<div class="content_left">
						<h2>Offices</h2>
						<p>In five countries across the globe</p>
					</div>
					<div class="rigt_img">
						<img src="/assets/media/images/img4.svg" alt="" />
					</div>
				</div>
				<div class="loop_patch d-flex align-items-center" style="background:#FEB5A7;">
					<div class="content_left">
						<h2>100+</h2>
						<p>Professional Services projects delivered in MENA/META</p>
					</div>
					<div class="rigt_img">
						<img src="/assets/media/images/img5.svg" alt="" />
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

</template>

<script>
export default {
  name: "AboutIntro",

  data() {
    return {
      cmsData: {},
      about_use_sec_1: {},
    }
  },
  methods: {
    getHome() {
      this.axios.get(this.apiURL + '/getCms?slug=about-us').then((response) => {
        this.cmsData = response.data.data.cmsData;
        this.about_use_sec_1 = response.data.data.cmsData[0];

        console.log(response.data.data);
      });
    }
  },
  beforeMount() {

  },
  mounted() {

    this.getHome();
  }
}
</script>

<style scoped>

</style>