<template>
  <div class="service_wrap box_area" style="background:transparent;" data-aos="fade-right">
    <div class="container">
      <div class="row">
        <div class="title">
          <span>OUR SOLUTIONS</span>
          <h2>{{ serviceD.sub_service_heading }}</h2>
        </div>
      </div>

      <div class="row">
        <div v-for="item in limitedItems" v-bind:key="item.id" class="col-md-4">
          <div class="flip-card soulation_loop">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <img :src="item.image_path" :alt="item.image_alt">
                <h2>{{ item.title }}</h2>
              </div>
              <div class="flip-card-back">
                <h2>{{ item.title }}</h2>
                <p>{{ item.short_description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SolutionsComponent",
  props: ['items','serviceD'],
  computed: {
    limitedItems() {
      const maxCharacters = 190; // Specify the maximum number of characters for short_desc

      return this.items.map(item => {
        if (item.short_description.length > maxCharacters) {
          return {
            ...item,
            short_description: item.short_description.slice(0, maxCharacters) + '...'
          };
        }
        return item;
      });
    }
  },

}
</script>

<style scoped>

</style>