<template>
<div class="container text-center">
  <img src="/images/404.jpg" style="max-width:80%;max-height: 400px" alt="">
  <h3>PAGE NOT FOUND</h3>
</div>
</template>

<script>
export default {
  name: "NotFoundComponent"
}
</script>

<style scoped>

</style>