<template>

  <div class="service_wrap contat_wrapper" data-aos="fade-right" id="contact-us">
    <div class="container">
      <div class="row">
        <div class="title">
          <span>CONTACT US</span>
          <h2>Supercharge Your Business with State-of-the-Art Services</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="contact_form">
            <form id="contact-form" @submit.prevent="onSubmit">
              <div class="row" id="dev_hire">

              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form_field">
                    <input type="text" id="name" name="name" v-model="formData.name" class="form-control" placeholder="Your Name">
                    <span class="error" v-if="errors && errors.name">
                      {{ errors.name[0] }}
                    </span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form_field">
                    <input type="text" id="email"  v-model="formData.email" class="form-control" placeholder="Your Email">
                  </div>
                  <span class="error" v-if="errors && errors.email">
                    {{ errors.email[0] }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form_field">
                    <vue-tel-input style="height: 55px;font-size: 14px;background: #fff;margin-bottom:20px" id="phone" type="tel" v-model="formData.phone" ref="phone" v-on:input="getPhoneNo($event)"></vue-tel-input>
                  </div>
                  <span class="error" v-if="errors && errors.phone">
                    {{ errors.phone[0] }}
                  </span>
                </div>
                <div class="col-md-6">
                  <div class="form_field">
                    <input type="text"  v-model="formData.budget" class="form-control" placeholder="Budget">
                  </div>
                  <span class="error" v-if="errors && errors.budget">
                    {{ errors.budget[0] }}
                  </span>
                </div>
                <div class="col-md-6">
                  <div class="form_field">
                    <input type="text"  v-model="formData.work" class="form-control" placeholder="How soon you want to start?">
                  </div>
                  <span class="error" v-if="errors && errors.work">
                    {{ errors.work[0] }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="md-form">
                    <textarea type="text" id="message" v-model="formData.message" rows="3" class="form-control"
                              placeholder="Describe your Requirement"></textarea>
                  </div>
                  <span class="error" v-if="errors && errors.message">
                    {{ errors.message[0] }}
                  </span>
                </div>
                <div class="col-md-12" >
                  <div class="md-form">
                    <div class="box">
                      <input type="file" name="files" id="files" class="inputfile inputfile-3" @change="upload_image"
                             data-multiple-caption="{count} files selected" accept=".png, .jpg, .jpeg, .webp, .svg"/>
                      <label for="files">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                          <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                          <path
                              d="M364.2 83.8c-24.4-24.4-64-24.4-88.4 0l-184 184c-42.1 42.1-42.1 110.3 0 152.4s110.3 42.1 152.4 0l152-152c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-152 152c-64 64-167.6 64-231.6 0s-64-167.6 0-231.6l184-184c46.3-46.3 121.3-46.3 167.6 0s46.3 121.3 0 167.6l-176 176c-28.6 28.6-75 28.6-103.6 0s-28.6-75 0-103.6l144-144c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-144 144c-6.7 6.7-6.7 17.7 0 24.4s17.7 6.7 24.4 0l176-176c24.4-24.4 24.4-64 0-88.4z"/>
                        </svg>
                        <span>Attach a file</span></label>
                    </div>
                  </div>
                  <span class="error" v-if="errors && errors.files">
                    {{ errors.files[0] }}
                  </span>
                </div>
              </div>
              <div class="col-md-12 d-flex justify-content-center">
                <input class="btn btn_red hvr-sweep-to-right gray" type="submit" value="Take The First Step">
                <!-- <a href="javascript:void(0)" class="btn btn_red hvr-sweep-to-right gray">Take The First Step</a> -->
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-6">
          <div class="right_info">

            <div class="loop d-flex">
              <div class="img">
                <img src="/assets/media/images/mail.svg" alt=""/>
              </div>
              <div class="content">
                <h2>Email</h2>
                <p>{{ configData.contact_email }}</p>
              </div>
            </div>

            <div class="loop d-flex">
              <div class="img">
                <img src="/assets/media/images/call.svg" alt=""/>
              </div>
              <div class="content">
                <h2>Contact Number</h2>
                <p>{{ configData.phone_1 }}</p>
              </div>
            </div>
            <div class="loop d-flex">
              <div class="img">
                <img src="/assets/media/images/address.svg" alt=""/>
              </div>
              <div class="content">
                <h2>Address</h2>
                <p>{{configData.address}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'

export default {
  name: "ContactUs",
  props:['setStyle'],
  components:{
    VueTelInput
  },
  data() {
    return {
      configData: {},
      errors: [],
      enquiryForm: false,
      formData: {
        name: "",
        image:"",
        budget:"",
        phone:"",
        email:"",
        work:"",
        message:"",
        subject:"",
      }
    }
  },
  methods: {
    getHome() {
      this.axios.get(this.apiURL + '/getConfigData').then((response) => {
        this.configData = response.data.config;

        console.log(this.configData);
      });
    },
    onSubmit() {
      let enquiry_form = this.enquiryForm;
      enquiry_form = true;


      this.axios.post(this.apiURL +"/enquiry/store",this.formData)
        .then(response => {
          console.log(response);
          console.log(enquiry_form);
              enquiry_form = false;
              this.errors = {};

              alert('Enquiry Sent Successfully');
              this.formData = {
                name: "",
                image:"",
                budget:"",
                phone:"",
                email:"",
                work:"",
                message:"",
                subject:"",
              }
          })
        .catch(error => {
          this.errors = error.response.data.errors;
          });
    },
    async upload_image(e) {
        let file = e.target.files[0];
        let reader = new FileReader();
          if (file["size"] < 2111775) {
              reader.onloadend = file => {
                console.log(file);
                  this.formData.image = reader.result;
              };
              reader.readAsDataURL(file);
          } else {
              alert("File size can not be bigger than 2 MB");
          }
    },
    getPhoneNo(ev){
        console.log(ev);
        this.formData.phone = this.$refs.phone.phoneObject.number
    }
  },
  beforeMount() {

  },
  mounted() {

    this.getHome();
  }

}
</script>

<style scoped>
.error{
  color: red;
}
.contat_wrapper .contact_form form .form-control{
  margin-bottom: 15px;
}
.vue-tel-input{
  border: none !important;
}
</style>