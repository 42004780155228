<template>
  <div   class="service_wrap pd" data-aos="fade-left">
    <div id="services-section" style="scroll-margin-top:150px;" class="container">
      <div class="row">
        <div class="title">
          <span>{{ section.title }}</span>
          <h2>{{ section.short_desc }}</h2>
        </div>
      </div>
      <div class="row">

        <div v-for="(item) in services" v-bind:key="item.id" class="col-md-3">
          <div class="loop_cat">

            <router-link :to="'/services/'+item.slug">
              <div class="img">
                <img :src="item.image_path" alt=""/>
              </div>
              <div class="content">
                <h2>{{ item.name }} <i class="fa-solid fa-angle-down"></i></h2>
                <p>{{ item.short_desc }}</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import lottie from 'lottie-web/build/player/lottie.min';

export default {
  name: "HomeServices",
  props: ['items', 'section'],
  data() {
    return {
      services: this.items,
      // path:'assets/json/web-development.json',
    }
  }
}
</script>

<style scoped>

</style>