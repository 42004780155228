<template>
	<BannersComponent :item="section.data.banner"  :hrefURL="''"  :hrefPath="'#contact-us'" :bannerTitle="''"></BannersComponent>
	<ContactUsComponent></ContactUsComponent>




<!-- {{ response }} -->


</template>


<script>

import BannersComponent from "../components/BannersComponent";
import ContactUsComponent from "../components/ContactUsComponent";

export default {
  name: "ContactUsPage",
  components: {
    BannersComponent,
    ContactUsComponent
  },
  data() {
    return {
      section: {

      },
    }
  },
  methods: {
    getContactUs() {
      this.axios.get(this.apiURL + '/getContactUs').then((response) => {
        this.section = response.data;
      });

      console.log(this.section);

    }
  },
  beforeMount() {
    this.getContactUs();
  },
  mounted() {
    console.log("AboutUs")

  }
}
</script>