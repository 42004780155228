<template>
  <div id="team-section" class="service_wrap plan logo_hide_tb" :style="isHome ? 'background:transparent;' : ''" data-aos="fade-right">
    <div class="container">
      <div class="row">
        <div class="title">
          <span>{{ section.title }}</span>
          <h2>{{ section.short_desc }}</h2>
        </div>
      </div>

      <div class="row">
        <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
          <li v-for="(item,index) in items" v-bind:key="item.id" class="nav-item" role="presentation">
            <button class="nav-link" :class="index == 0 ? 'active': ''" :id="item.title+'-tab'" data-bs-toggle="tab" :data-bs-target="'#'+stringToSlug(item.title+'-content')" v-on:click="changeTab($event,'#'+stringToSlug(item.title+'-content'))" type="button" role="tab" aria-controls="Mobile" aria-selected="true">
              {{ item.title }}</button>
          </li>

        </ul>
        <div class="tab-content" id="myTabContent">
          <div v-for="(item,index) in items" v-bind:key="item.id"  class="tab-pane fade show " :class="index == 0 ? 'active': ''" :id="stringToSlug(item.title+'-content')" role="tabpanel" aria-labelledby="Mobile-tab">
            <div class="row">
                <div v-for="developer in item.expertise" v-bind:key="developer.id" class="col-md-2">
                  <div class="dev_team_loop" :class="isHome ? '':'bg-loop'">
                    <div class="img">
                      <img :src="developer.image_path" alt="">
                    </div>
                    <div class="title_tag">
                      <span>{{ developer.designation }}</span>
                      <h2>{{ developer.job_title }}</h2>
                    </div>
                    <div class="info_tb">
                      <ul>

                        <li><img src="/assets/media/images/dev/dev3.svg" alt="" title="Rate"> <span>${{ developer.rate_per_hours }} /hr</span></li>
                        <li><img src="/assets/media/images/dev/dev4.svg" alt=""  title="Availability"> <span>{{ developer.is_available }}</span></li>
                      </ul>
                    </div>
                    <div class="teck_loop">
                      <ul class="nav justify-content-center">
                        <li v-for="technology in developer.technologies" v-bind:key="technology.id">
                          <img :src="technology.icon_path" alt="">
                        </li>
                      </ul>
                      <ul class="nav justify-content-center">
                        <li v-for="technology in developer.solutions" v-bind:key="technology.id">
                          <img :src="technology.icon_path" alt="">
                        </li>
                      </ul>
                    </div>
                    <div class="btn_bt">
                      <a class="btn btn_red hvr-sweep-to-right gray" href="#contact-us" v-on:click="getDeveloper(developer)">Hire Now</a>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "MultiDeveloperComponent",
  props: ['items','section','isHome'],
  methods: {
    getDeveloper(developer){
        document.getElementById("dev_hire").innerHTML= '<div class="col-md-6">\
                  <div class="form_field">\
                    <input type="text" id="dev_name" value="'+ developer.job_title +'" class="form-control" placeholder="Developer Name" readonly>\
                    <input type="hidden" id="dev_id" value="'+ developer.id +'" v-model="formData.dev_id" class="form-control">\
                  </div>\
                </div>\
                <div class="col-md-6">\
                  <div class="form_field">\
                    <input type="text" id="dev_hour_rate" v-model="formData.dev_hour_rate" value="'+ developer.rate_per_hours +'" class="form-control" placeholder="Per Hour Rate" readonly>\
                  </div>\
                </div>';
        console.log(developer);
      },
    stringToSlug(str) {
      str = str.toLowerCase(); // Convert the string to lowercase
      str = str.replace(/[^a-z0-9]/g, '-'); // Replace non-alphanumeric characters with a hyphen
      str = str.replace(/-+/g, '-'); // Replace multiple consecutive hyphens with a single hyphen
      str = str.trim('-'); // Trim any leading or trailing hyphens
      return str;
    }
  },

}
</script>

<style scoped>
  .dev_team_loop.bg-loop{
    box-shadow: 0px 0px 8px 1px #545454c7;
    background: #fff;
  }

  .form-control[readonly]{
    background: #fff !important;
  }
</style>