<template>
  <div class="service_wrap box_area" style="background:transparent;" data-aos="fade-right">
    <div class="container">
      <div v-if="heading" class="row">
        <div class="title motionless__banner">
          <span> WHO WE ARE? </span>
          <h2>Boost Business with IT Staff Augmentation</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div v-for="item in items" v-bind:key="item.id" class="loop_patch d-flex align-items-center">
            <div class="content_left">
              <h2>{{ item.stat_title_1 }}</h2>
              <p>{{ item.stat_short_desc_1 }}</p>
            </div>
            <div class="rigt_img">
              <img :src="item.stat_image_url_1_path" :alt="item.image_alt">
            </div>
          </div>
          <div v-for="item in items" v-bind:key="item.id" class="loop_patch d-flex align-items-center"
               style="background: #A8E8F3;">
            <div class="content_left">
              <h2>{{ item.stat_title_2 }}</h2>
              <p>{{ item.stat_short_desc_2 }}</p>
            </div>
            <div class="rigt_img">
              <img :src="item.stat_image_url_2_path" :alt="item.image_alt">
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div v-for="item in items" v-bind:key="item.id" class="loop_patch d-flex align-items-center half"
               style="background:#FAE6DF;">
            <div class="content_left">
              <h2>{{ item.stat_title_3 }}</h2>
              <p>{{ item.stat_short_desc_3 }}</p>
            </div>
            <div class="rigt_img">
              <img :src="item.stat_image_url_3_path" :alt="item.image_alt">
            </div>
          </div>
          <div v-for="item in items" v-bind:key="item.id" class="loop_patch d-flex align-items-center half"
               style="background:#D4F0FD;">
            <div class="content_left">
              <h2>{{ item.stat_title_4 }}</h2>
              <p>{{ item.stat_short_desc_4 }}</p>
            </div>
            <div class="rigt_img">
              <img :src="item.stat_image_url_4_path" :alt="item.image_alt">
            </div>
          </div>
          <div v-for="item in items" v-bind:key="item.id" class="loop_patch d-flex align-items-center"
               style="background:#FEB5A7;">
            <div class="content_left">
              <h2>{{ item.stat_title_5 }}</h2>
              <p>{{ item.stat_short_desc_5 }}</p>
            </div>
            <div class="rigt_img">
              <img :src="item.stat_image_url_5_path" :alt="item.image_alt">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "StatsComponent",
  props: ['items', 'heading'],
  components: {},
}
</script>

<style scoped>

</style>