<template>

  <div id="technologies-section" class="service_wrap plan" style="scroll-margin-top:85px;" :style="isHome ? 'background:transparent;' : ''" data-aos="fade-right">
    <div  class="container">
      <div class="row">
        <div class="title">
          <span>{{ section.title }}</span>
          <h2>{{ section.short_desc }}</h2>
        </div>
      </div>

      <div class="row">
        <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
          <li v-for="(cat,index) in item.categories" v-bind:key="cat.id" class="nav-item" role="presentation">
            <button class="nav-link" :class="index == 0 ? 'active': ''" :id="cat.title+'-tab'" data-bs-toggle="tab" :data-bs-target="'#'+stringToSlug(cat.title+'-content')" v-on:click="changeTab($event,'#'+stringToSlug(cat.title+'-content'))" type="button" role="tab" aria-controls="Mobile" aria-selected="true">
              {{ cat.title }}</button>
          </li>

        </ul>
        <div class="tab-content" id="myTabContent">
          <div v-for="(cat,index) in item.categories" v-bind:key="cat.id"  class="tab-pane fade show " :class="index == 0 ? 'active': ''" :id="stringToSlug(cat.title+'-content')" role="tabpanel" aria-labelledby="Mobile-tab">
            <div class="row">
              <template v-for="(tech) in item.items" v-bind:key="tech.id">
                <div v-if="tech.category_id == cat.id" class="col-25">
                  <div class="loop_logo">
                    <img :src="tech.image_path" alt="" />
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!isHome" class="row justify-content-center">
        <a class="btn btn_red bnt_tb" href="#">HIRE DEDICATED DEVELOPER</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TechnologyComponent",
  props: ['item','section','isHome'],
  methods: {

    stringToSlug(str) {
      str = str.toLowerCase(); // Convert the string to lowercase
      str = str.replace(/[^a-z0-9]/g, '-'); // Replace non-alphanumeric characters with a hyphen
      str = str.replace(/-+/g, '-'); // Replace multiple consecutive hyphens with a single hyphen
      str = str.trim('-'); // Trim any leading or trailing hyphens
      return str;
    }
  },
}
</script>

<style scoped>
.col-25 {
  width: 20%;
}
.service_wrap.plan .col-25 {
  padding-right: 0;
}
.service_wrap.plan .col-25:nth-child(5n+5){ padding-right:calc(var(--bs-gutter-x) * .5); }
</style>