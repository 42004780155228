<template>
  <div class="service_wrap box_area pb-0" style="background:transparent;" data-aos="fade-right">
    <div class="container">
      <div class="row">
        <div class="title">
          <span>OUR SOLUTIONS</span>
          <h2>{{ serviceD.sub_service_heading }}</h2>
        </div>
      </div>

      <div class="row">
        <div v-for="item in limitedItems" v-bind:key="item.id" class="col-md-3">
          <div class="soulation_loop new_desg">
            <a v-on:click="moveFocus('sub_service_'+item.id)" href="javascript:void(0)">
            <img :src="item.image_path" :alt="item.image_alt">
            <h2>{{ item.title }}</h2></a>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="">
    <div class="container">
      <div class="row seerv_new_loop section-toggle"  v-for="(item,index) in items" v-bind:key="item.id" :class="index == 0 ? 'close' : 'close'" :id="'sub_service_'+item.id">
        <div class="col-md-4">
          <div class="img_left">
            <div class="wop_img">
              <img :src="item.image_path" :alt="item.image_alt"/>
              <router-link to="/contact-us" class="btn btn_red active hvr-sweep-to-right gray">Get a Quote</router-link>
            </div>
          </div>
        </div>
        <div class="col-md-8 d-flex align-items-center">
          <div class="content_right">
            <h2>{{ item.title }}</h2>
            <div class="short_description"  v-html="item.short_description"></div>
            <div class="description"  v-html="item.description"></div>
            <a class="toggle_button" v-on:click="toggleLoopClass($event,'#sub_service_'+item.id)" href="javascript:void(0)"></a>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "SolutionsComponent",
  props: ['items', 'serviceD'],
  computed: {
    limitedItems() {
      const maxCharacters = 190; // Specify the maximum number of characters for short_desc

      return this.items.map(item => {
        if (item.short_description.length > maxCharacters) {
          return {
            ...item,
            short_description: item.short_description.slice(0, maxCharacters) + '...'
          };
        }
        return item;
      });
    }
  },
  methods:{
    toggleLoopClass(ev,target){

      if(document.querySelector(target).classList.contains('open')){
        document.querySelector(target).classList.add('close');
        document.querySelector(target).classList.remove('open');

      }else{
        document.querySelectorAll('.section-toggle').forEach(element => {
          element.classList.add('close');
        })
        document.querySelectorAll('.section-toggle').forEach(element => {
          element.classList.remove('open');
        })

        document.querySelector(target).classList.add('open')
        document.querySelector(target).classList.remove('close')
      }
    },
    moveFocus(target) {
      const ele = document.getElementById(target);

      if (ele) {
        const currentTop = ele.offsetTop;
        console.log(currentTop)
        window.scrollTo(0, currentTop - 110);
      }
    }
  }

}
</script>

<style scoped>
.short_description,.description{
  display: none;
}

 .seerv_new_loop.close .short_description{
   display: block !important;
 }
 .seerv_new_loop.open .description{
   display: block !important;
 }
</style>