<template>
  <BannersComponent :item="section.data.banner" :hrefURL="'/contact-us'"  :hrefPath="'#contact-us'" :bannerTitle="''"></BannersComponent>
	<AboutIntro></AboutIntro>
	<AboutApproach></AboutApproach>
</template>


<script>

import AboutIntro from "../components/AboutIntro";
import AboutApproach from "../components/AboutApproach";
import BannersComponent from "@/components/BannersComponent";

export default {
  name: "AboutUsPage",
  components: {
    BannersComponent,
    AboutIntro,
    AboutApproach
  },
  data() {
    return {
      section: {

      },
    }
  },
  methods: {
    getHome() {
      this.axios.get(this.apiURL + '/getabout').then((response) => {
        this.section = response.data;
      });

      console.log(this.section);

    }
  },
  beforeMount() {
    this.getHome();
  },
  mounted() {
    console.log("AboutUs")

  }
}
</script>