<template>
    
    <div class="service_wrap" style="background:transparent;" data-aos="fade-left">
	<div class="container">
		<div class="row">
			<div class="title motionless__banner">
				<span>HIRING</span>
				<h2>Hiring Process We Follow</h2>
			</div>
		</div>

		<div class="row follow_wrap">
			<div class="col-md-4">
				<div class="flow_loop">
					<div class="icone">
						<img src="/assets/media/images/icone/icone1.png" alt="">
					</div>
					<div class="cont">
						<h2>One-to-one conversation with our team of industry experts</h2>
						<p>A dedicated Fast Services Team Lead will collaborate with you to gain insights into your objectives, technical requirements, and team dynamics.</p>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="flow_loop">
					<div class="icone">
						<img src="/assets/media/images/icone/icone2.png" alt="">
					</div>
					<div class="cont">
						<h2>Work with a hand-picked pool of talent</h2>
						<p>In a matter of days, we will connect you with the ideal developer for your project. Our average time to match is less than 24 hours.</p>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="flow_loop">
					<div class="icone">
						<img src="/assets/media/images/icone/icone3.png" alt="">
					</div>
					<div class="cont">
						<h2>Ensure the perfect fit with our guaranteed trial period</h2>
						<p>Collaborate with your chosen developer and evaluate their suitability during this trial period. You only pay if you are completely satisfied, providing peace of mind before committing to a long-term engagement.</p>
					</div>
				</div>
			</div>
		</div>
		
	</div>
</div>
    
</template>

<script>
export default {
  name: "PortfolioHiring"
}
</script>

<style scoped>

</style>