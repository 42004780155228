<template>
  <div id="solution" style="scroll-margin-top:85px;"  class="service_wrap plan mob" data-aos="fade-left" :style="setStyle">
    <div class="container">
      <div class="row">
        <div class="title">
          <span>SOLUTIONS OFFERING</span>
          <h2>Elevate Success - Tailored Services, Tailored Solutions!</h2>
        </div>
      </div>
      <div class="row">
        <ul class="nav nav-tabs justify-content-center" id="myTab-solution" role="tablist">
          <li v-for="(cat,index) in item.categories" v-bind:key="cat.id" class="nav-item" role="presentation">
            <button class="nav-link" :class="index == 0 ? 'active': ''" :id="cat.title+'-tab'" data-bs-toggle="tab" :data-bs-target="'#'+stringToSlug(cat.title+'-content')" type="button" role="tab" aria-controls="Mobile" aria-selected="true">
              {{ cat.title }}</button>
          </li>
        </ul>
        <div class="tab-content" id="mySolutions">
          <div v-for="(cat,index) in item.categories" v-bind:key="cat.id"  class="tab-pane fade show " :class="index == 0 ? 'active': ''" :id="stringToSlug(cat.title+'-content')" role="tabpanel" aria-labelledby="Mobile-tab">
            <div class="row">
              <template v-for="(solution) in item.items" v-bind:key="solution.id">
                <div v-if="solution.category_id == cat.id" class="col-25">
                  <div class="loop_logo">
                    <img :src="solution.image_path" alt="" />
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeSolutions",
  props: ['item','setStyle'],
  methods: {

    stringToSlug(str) {
      str = str.toLowerCase(); // Convert the string to lowercase
      str = str.replace(/[^a-z0-9]/g, '-'); // Replace non-alphanumeric characters with a hyphen
      str = str.replace(/-+/g, '-'); // Replace multiple consecutive hyphens with a single hyphen
      str = str.trim('-'); // Trim any leading or trailing hyphens
      return str;
    }
  },
}
</script>

<style scoped>
.col-25 {
  width: 20%;
}
.service_wrap.plan .col-25 {
  padding-right: 0;
}
.service_wrap.plan .col-25:nth-child(5n+5){ padding-right:calc(var(--bs-gutter-x) * .5); }
</style>