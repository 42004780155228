<template>
  <BannersComponent :item="section.data.banner" :hrefURL="''"  :hrefPath="'#team-section'" :bannerTitle="'Hire Developer'"></BannersComponent>
  <HiringDeveloperProcess></HiringDeveloperProcess>
<!--  <TechnologyComponent :item="section.data.technologies" :section="{title:'TECHNOLOGY STACK',short_desc:'Explore Boundless Potential - Our Tech Stack Awaits!'}" :isHome="false"></TechnologyComponent>
  <PortfolioComponent :toShow="1" :slug="1"></PortfolioComponent>
  <PortfolioComponent :toShow="1" :slug="0"></PortfolioComponent>-->
  <MultiDeveloperComponent :items="section.data.technologyList" :section="{title:'OUR DEVELOPERS',short_desc:'Unlock Your Vision - Hire a Developer Today!'}" :isHome="false"></MultiDeveloperComponent>
  <MultiDeveloperComponent :items="section.data.solutionList" :section="{title:'OUR CONSULTANTS',short_desc:'Elevate Your Strategy - Hire a Consultant Now!'}" :isHome="true"></MultiDeveloperComponent>
  <ContactUs></ContactUs>
</template>
<script>
import BannersComponent from "@/components/BannersComponent";
import HiringDeveloperProcess from "@/components/HiringDeveloperProcess";
import ContactUs from "@/components/ContactUs";
// import TechnologyComponent from "@/components/TechnologyComponent";
// import PortfolioComponent from "@/components/PortfolioComponent";
import MultiDeveloperComponent from "@/components/MultiDeveloperComponent";

export default {
  name: "HireDeveloperPage",
  components: {
    MultiDeveloperComponent,
    // PortfolioComponent,
    HiringDeveloperProcess,
    // TechnologyComponent,
    ContactUs,
    BannersComponent
  },
  data() {
    return {
      section: {},
    }
  },
  methods: {
    getManagement() {
      this.axios.get(this.apiURL + '/getDeveloper').then((response) => {
        this.section = response.data;
      });

      console.log(this.section);

    }
  },
  beforeMount() {
    this.getManagement();
  },
  mounted() {
    console.log("AboutUs")

  }
}
</script>